html, body{
  /* font-family: 'Poppins', sans-serif; */
  /* background: #f7e7ce; */
  height: 100vh;
}
.landing{
  padding-top: 200px;
  background-color: #0066b2;
  height: 100vh;
}
.App {
  text-align: center;
  
}
.newRecipe{
  background-color: white;
  display: inline-block;
  padding-left:20px;
}
.dashboard-apiSuggestions{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-bottom: 20px;
}

.dashboard-cookbook{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-bottom: 20px; 
}

.events-componentUD{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-start:1;
  grid-column-end:4;
  margin-bottom: 20px;
}

.cookbookDisplay{
  display:grid;
  grid-template-columns: repeat(3, 1fr); 
}

.allEventsDisplay{
  display:grid;
  grid-template-columns: repeat(3, 1fr);
}

.api-img img{
  width: 250px;
  height:auto
}
.events-componentUD{
  margin-bottom: 20px;
}

.dashboard-cookbook{
  margin-bottom: 20px;
}

ul{
  list-style-type: none;
}

ol{
  list-style-position: inside;
}